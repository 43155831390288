import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import rates from '../images/great-rates.svg'
import hometime from '../images/home-time.svg'
import ontime from '../images/ontime.svg'
import {
  primary,
  secondary,
  darkGrey,
  mdGrey,
  grey,
  tertiary,
  xltGrey,
  above,
  below,
  BoxWrapper,
  Box,
  BoxInner,
  CardTitle,
  Button,
  DownloadButton,
  LinkWrapper,
} from '../elements'
import { sansCondBold } from '../utilities'
import hero from '../images/slideshow/ddtsi-driver-looking-forward@2x.png'
import sky from '../images/slideshow/happy-horizon@2x.png'

const Index = ({ data }) => {
  const postNode = {
    title: `Homepage - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Index - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="contact" customTitle />

      <DriverFeature>
        <div className="driver-content">
        <h4 className="banner">Introducing new driver pay</h4>
          <h2>Always Looking Forward</h2>
          <p>
          It has been a challenging year for everyone, especially for truck drivers. That’s why we've made it a priority to increase our driver pay-scale upwards of 30%.
          </p>
          
          <DownloadButton
            modifiers={['dark', 'margintop']}
            rel="noopener noreferrer"
            href="https://intelliapp.driverapponline.com/c/ddtsi"
            target="_blank"
          >
            Apply Today!&nbsp;
            <i className="material-icons">arrow_forward</i>
          </DownloadButton>
          <Button
            to="/drivers"
            modifiers={['margintop', 'marginleft']}
          >
            Learn More&nbsp;
            <i className="material-icons">arrow_forward</i>
          </Button>

        </div>
        <div className="looking-forward">
          <img
            src={hero}
            alt="Always looking forward"
          />
        </div>
      </DriverFeature>

      <Container>
        {/* <PageTitle>Index</PageTitle> */}
        <BoxWrapper>
          <IndexBox>
            <LinkWrapper to="/shippers">
              <BoxInner>
                <ServiceIcon
                  src={ontime}
                  alt="On-time and Damage Free Freight"
                />
                <CardTitle>On-time, Damage Free</CardTitle>
                <p>
                  We understand transportation challenges. Whether you are
                  looking for a long-term logistics partner or help moving a
                  load quickly, D&D can help.
                </p>
                <Button modifiers={['center', 'centerhanging']} to="/shippers">
                  Our Services&nbsp;
                  <i className="material-icons">chevron_right</i>
                </Button>
              </BoxInner>
            </LinkWrapper>
          </IndexBox>
          <IndexBox>
            <LinkWrapper to="/carriers">
              <BoxInner>
                <ServiceIcon src={rates} alt="Great Load Rates" />
                <CardTitle>Assured Loads, Great Rates</CardTitle>
                <p>
                  Staying in compliance and finding loads in lanes that make a
                  profit can be difficult. We understand your job is more than
                  just pickup and delivery.
                </p>
                <Button modifiers={['center', 'centerhanging']} to="/carriers">
                  Get Loaded&nbsp;
                  <i className="material-icons">chevron_right</i>
                </Button>
              </BoxInner>
            </LinkWrapper>
          </IndexBox>
          <IndexBox>
            <LinkWrapper to="/drivers">
              <BoxInner>
                <ServiceIcon src={hometime} alt="Home time guaranteed" />
                <CardTitle>Home Regularly, Guaranteed</CardTitle>
                <p>
                  Drivers are the backbone of our company and of the Nation. We
                  show our gratitude by offering competitive pay packages and
                  robust benefits.
                </p>
                <Button modifiers={['center', 'centerhanging']} to="/drivers">
                  Find a Balance&nbsp;
                  <i className="material-icons">chevron_right</i>
                </Button>
              </BoxInner>
            </LinkWrapper>
          </IndexBox>
        </BoxWrapper>
      </Container>
    </Layout>
  )
}

export default Index

const IndexBox = styled(Box)`
  ${below.lg`
    flex: 0 0 100%;
  `};
`
const ServiceIcon = styled.img`
  width: 120px;
  float: right;
  margin: -1rem -1rem 0 0;
  padding: 0 0 1rem 1rem;
`
const DriverFeature = styled.section`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  ${above.lg`
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    max-height: 800px;
  `}
  .driver-content {
    padding: 20px;
    margin: -80px 1.5rem 1.5rem 1.5rem;
    background: white;
    text-align: center;
    h4.banner {
      color: ${primary};
      text-align: center;
      text-transform: uppercase;
      text-shadow: 
        1px 1px 0px white, 
        2px 2px 0px rgba(0, 0, 0, 0.2);
      ${above.md`
        font-size: 2.2rem;
      `}
      ${above.lg`
        background-color: ${darkGrey};
        color: ${primary};
        font-size: 2.05rem;
        padding: 0 30px;
        height: 80px;
        line-height: 80px;
        position: relative;
        margin-bottom: 2rem;
        display: inline-block;
        text-shadow: none;
      `};
      ${above.xl`
        /* font-size: 2.05rem; */
        /* line-height: 2.4rem; */
      `};
    }
    h4.banner:before, h4.banner:after {
      background-color:black; 
      width:50px; height:80px;
      content:'';
      display:block; 
      transform:skewY(20deg);
      position:absolute;
      z-index:-1;
    }
    h4.banner:before {top:10px; left:0; }
    h4.banner:after {top:-10px; right:0; }
    ${above.md`
      margin-top: -100px;
    `}
    ${above.lg`
      margin: 0 3rem 0 2rem;
      background: none;
      text-align: left;
      flex: 2 1 auto;
    `}
    ${above.xl`
      max-width: 800px;
      margin-left: 3.5rem;
    `}
  }
  .looking-forward {
    width: 100%;
    z-index: -1;
    ${above.md`
      overflow: hidden;
    `}
    ${above.lg`
      width: auto;
      text-align: right;
    `}
    img {
      display: block;
      width: auto;
      overflow: hidden;
      margin-top: -75px;
      ${above.md`
        margin-top: -200px;
      `}
      ${above.lg`
        margin: 0;
        min-height: 400px;
        max-height: 800px;
        float: right;
      `}
    }
  }
  h2 {
    font-size: 3.25rem;
    line-height: 3.75rem;
    ${above.xl`
      font-size: 4.35rem;
      line-height: 3.95rem;
      margin-bottom: 1.5rem;
    `};
  }
  p {
    font-size: 1.18rem;
    line-height: 1.75rem;
    margin-bottom: 1.2rem;
    ${above.md`
      font-size: 1.4rem;
      line-height: 2rem;
    `}
    ${above.xl`
      font-size: 1.6rem;
      line-height: 2.2rem;
    `}
  }
  p.driver-pay-note {
    ${sansCondBold};
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: -1;
    transition: 0.3s all ease;
    ${above.lg`
      background: linear-gradient(135deg, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 80%);
    `};
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    background-image: url('${sky}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
  }
  &:hover {
    &:before {
      opacity: 0.9;
    }
  }
`